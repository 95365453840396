<template>
	<div>
		<!--查看合并提案-->
		<el-dialog title="查看合并提案" :visible.sync="showyy" width="70%">
			<div style="height: 60vh;overflow-y: auto;">
				<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader"
					:showbm="true" :czwidth="100" :showpages="false">
					<template slot="btns" slot-scope="{ scope }">
						<el-button @click="look(scope.row)"
							type="primary" size="mini" style="margin-left: 10px;">
							详情
						</el-button>
					</template>
				</MyTable>
			</div>
		</el-dialog>
		
		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">		
		</mydetail>
	</div>
	
</template>

<script>
	import mydetail from "@/components/detail_tc.vue";
	export default{
		components: {
			mydetail
		},
		data() {
			return{
				tableHeader: [
					{
						name: '提案标题',
						prop: 'name',
						width: ''
					},
					{
						name: '提案类型',
						prop: 'lei_name',
						width: '120'
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: '150'
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: '180'
					}
				],
				tableData: [],
				showyy:false,
				showflag:false,
				showtitle:'',
				htmldiv:'',
				showdetail: false,
				detail_id:''
			}
		},
		methods:{
			showtext(item,i){//i=1 查看答复，i=2 查看承诺
				this.htmldiv = i==1?item.dfh:item.blcl
				this.showtitle = i==1?'查看答复':'查看承诺'
				this.showflag = true
			},
			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			lookyy(item) {
				this.$alert('异议：'+item.yyly, '查看异议', {
					confirmButtonText: '确定',
					callback: action => {
						
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .cover{
		z-index: 3000;
	}
</style>